<template>
    <surround-loader :is-loading="isLoading">
        <div class=" border rounded p-3 bg-white">
            <h5>Create new {{ supervisor != null ? "supervisor" : "user" }}</h5>
            <div class="d-flex align-items-start">
                <div class="flex-fill p-2">

                    <b-form-input placeholder="First name" size="sm"
                                  v-model="user.first_name"></b-form-input>
                    <error-display v-model="errors" ident="first_name"></error-display>

                </div>

                <div class="flex-fill p-2">

                    <b-form-input placeholder="Last name" size="sm"
                                  v-model="user.last_name"></b-form-input>
                    <error-display v-model="errors" ident="last_name"></error-display>

                </div>
                <div class="flex-fill p-2">

                    <b-form-input placeholder="Email address" size="sm"
                                  v-model="user.email"></b-form-input>
                    <error-display v-model="errors" ident="email"></error-display>

                </div>

                <div class="flex-fill p-2">
                <b-form-checkbox
                        class="font-weight-bold"
                        v-model="user.billing"
                        value="1"
                        unchecked-value="0"
                >
                    <b-badge variant="success">
                        Billing access
                    </b-badge>
                </b-form-checkbox>
                </div>

                <div v-if="supervisor != null" class="flex-fill p-2">
                    <b-form-checkbox
                            class="font-weight-bold"
                            v-model="user.is_admin"
                            value="1"
                            unchecked-value="0"
                    >
                        <b-badge variant="primary">
                            Admin access
                        </b-badge>
                    </b-form-checkbox>
                </div>

                <div v-else class="flex-fill p-2">
                    <label>Supervisors</label>
                    <v-select
                            ref="userSelect"
                            label="name"
                            v-model="user.user_ids"
                            :multiple="true"
                            :reduce="option => option.id"
                            :options="supervisors"
                            :filterable="true"
                            placeholder="Search for user">
                    </v-select>
                    <div class="mt-2 text-right">
                        <b-button :pill="true" variant="secondary" size="sm" @click="deselectUser">Deselect
                            all
                        </b-button>
                        <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                  @click="selectAllUsers">Select all
                        </b-button>
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-end p-2">
                <b-button @click="saveForm" size="sm" variant="success">
                    Add {{ supervisor != null ? "supervisor" : "user" }}
                </b-button>
            </div>
        </div>
    </surround-loader>
</template>

<script>
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import {saveResource} from "../../modules/api/methods";
    import {organisationSetupUsers} from "../../modules/api/endpoints";
    import SurroundLoader from "../SurroundLoader";
    import vSelect from "vue-select"
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "SetupUser",
        components: {SurroundLoader, ErrorDisplay,"v-select": vSelect},
        props: {
            supervisor: null,
            supervisors: {
                type: Array,
                default: null
            }
        },
        data: function () {
            return {
                isLoading: false,
                user: {},
                errors: {},
                users: [],
            }
        },
        methods: {
            selectAllUsers() {
                var user = this.user;
                user.user_ids = _.map(this.users, 'value');
                this.user = user;
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectUser() {
                var user = this.user;
                user.user_ids = [];
                this.user = user;
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            saveForm() {
                let vm = this;
                vm.errors = {};
                vm.isLoading = true;
                if(vm.supervisor != null) {
                    vm.user.user_type = 'supervisor';
                } else {
                    vm.user.user_type = 'user';
                }
                saveResource(organisationSetupUsers, vm.user).then(function (resp) {
                    console.log(resp);
                    vm.user = {};
                    vm.$emit('new-user');
                    vm.$bvToast.toast((vm.supervisor != null ? 'Supervisor added' : 'User added'), {
                        title: 'Done',
                        variant: 'success',
                        solid: false
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            }
        }

    }
</script>

<style scoped>

</style>
