import { render, staticRenderFns } from "./SetupUser.vue?vue&type=template&id=43493257&scoped=true&"
import script from "./SetupUser.vue?vue&type=script&lang=js&"
export * from "./SetupUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43493257",
  null
  
)

export default component.exports