<template>
    <div>
        <surround-loader :is-loading="isLoading">

            <h2 class="mb-0 mx-4 mt-4 mb-2">System setup</h2>
            <div class="px-3 py-2">
                <p class="mx-2">Here you can configure your system to suit your organisation's individual needs.</p>

                <div class="">
                    <div class="d-flex">
                        <div class="flex-fill col-12 p-4 border rounded m-1">
                            <h6 class="mb-2">Document check </h6>
                            <p class="text-muted" style="font-size: 12px;">Automatic checks for documents containing an MRZ code e.g. passports and Visas</p>
                            <b-form-group
                                    label-size="sm">
                                <b-select size="sm" v-model="formData.doc_check_mode"
                                          :options="docCheckOptions"
                                          placeholder=""></b-select>
                                <error-display ident="doc_check_mode" v-model="errors"></error-display>
                            </b-form-group>
                            <div class="alert alert-info p-2 d-flex align-items-center mt-3" style="font-size: 14px;">
                                <b-icon-info-circle class="mr-2" style="font-size:20px;"></b-icon-info-circle>
                                {{ explanation() }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="flex-fill col-6 p-4 border rounded m-1">
                            <h6 class="mb-4">Capturing candidate photo</h6>
                            <b-form-checkbox
                                    size="sm"
                                    v-model="formData.is_photo_required"
                                    :value="1"
                                    unchecked-value="0"
                            >Tick this box to make capturing candidate photo mandatory
                            </b-form-checkbox>

                        </div>

                        <div class="flex-fill col-6 p-4 border rounded m-1">
                            <h6 class="mb-4">Application approval</h6>
                            <b-form-checkbox
                                    size="sm"
                                    v-model="formData.force_flagging"
                                    :value="1"
                                    unchecked-value="0"
                            >Tick this box to only allow supervisors to approve/reject applications. If left unticked,
                                users will be able to approve applications from the mobile and web app.
                            </b-form-checkbox>

                        </div>

                    </div>

                </div>
            </div>


            <div class="p-4  d-flex justify-content-between">
                <b-button :to="{name:'setup_company_details'}" variant="outline-secondary">
                    Back
                </b-button>

                <b-button @click="saveForm" variant="success">
                    Proceed to User setup
                </b-button>
            </div>
        </surround-loader>
    </div>
</template>

<script>
    import Vue from "vue";
    import SurroundLoader from "../SurroundLoader";
    import {getResource, saveResource} from "../../modules/api/methods";
    import {
        organisationCurrent, organisationSetupSystemSetup, organisationSetupUsers,
    } from "../../modules/api/endpoints";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import SetupUser from "./SetupUser";
    import _ from 'lodash'
    import SetupUserCell from "./SetupUserCell";
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: 'setup_system_setup',
        data: function () {
            return {
                isLoading: false,
                isLoadingUsers: false,
                formData: {},
                errors: {},
                users: [],
                supervisors: [],
                newSupervisor: {},
                newUser: {},
                docCheckOptions: [
                    {value: 'on_upload', text: 'Automatically on upload', 'explanation' : 'Documents will automatically be checked upon upload'},
                    {value: 'on_approve', text: 'Automatically on approval','explanation' : 'Documents will automatically be checked once an application has been approved'},
                    {value: 'on_request', text: 'All users can request', 'explanation' : 'All users can request documents checks'},
                    {value: 'on_request_supervisor', text: 'Supervisors can request', 'explanation' : 'Only supervisors can request documents checks'},
                    {value: 'off', text: 'Off', 'explanation' : 'Document checks will be disabled for all users in your organisation'},
                ],
            }
        },
        created() {
            this.loadModel();
            this.loadUsers();
        },
        methods: {
            explanation() {
                if(this.formData.doc_check_mode == null){
                    return null;
                }
                return this.docCheckOptions.filter((obj) => { return obj.value === this.formData.doc_check_mode }).map((obj) =>{ return obj.explanation})[0];
            },
            loadModel() {
                this.isLoading = true;
                getResource(organisationCurrent).then((resp) => {
                    var data = resp.data.success.data;
                    if (data.domains !== undefined) {
                        var domains = [];
                        _.each(data.domains, function (obj) {
                            if (obj.domain !== undefined) domains.push(obj.domain);
                        });
                        data.domains = domains;
                    }

                    if (data.ips !== undefined) {
                        var ips = [];
                        _.each(data.ips, function (obj) {
                            if (obj.ip_address !== undefined) ips.push(obj.ip_address);
                        });
                        data.ips = ips;
                    }

                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            loadUsers() {
                this.isLoadingUsers = true;
                var vm = this;
                getResource(organisationSetupUsers).then((resp) => {
                    console.log("ALL USERS", resp);
                    vm.users = resp.data.success.filter(function (obj) {
                        return obj.user_type === 'user'
                    });
                    vm.supervisors = resp.data.success.filter(function (obj) {
                        return obj.user_type === 'supervisor'
                    });

                }).catch((ex) => {
                    vm.$root.$children[0].handleApiError(ex, this.loadUsers);
                }).finally(() => {
                    vm.isLoadingUsers = false;
                });
            },
            saveForm() {
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                saveResource(organisationSetupSystemSetup, vm.formData).then(function (resp) {
                    //UPDATE THE ORG TO ALLOW TO PROCEED TO NEXT STEP
                    console.log(resp.data.success.data);
                    vm.$store.dispatch('updateOrganisation', resp.data.success.data).then(() => {
                        vm.$router.push({name: 'setup_users_setup'}).catch(error => {});
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            },
            ipValidator(tag) {
                return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(tag)
            },
        },
        components: {SetupUserCell, SetupUser, ErrorDisplay, SurroundLoader},
    }
</script>

