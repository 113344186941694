<template>
    <surround-loader style="min-height: 50px!important;" :is-loading="isLoading">
        <div class="border-primary rounded p-3 bg-light mb-2 d-flex align-items-center" style="border-left: solid 3px;">
            <div class="flex-wrap" style="font-size:14px;">
                <h6 :class="user.name == null ? 'text-danger font-weight-bold' : ''">{{ user.name == null ? "No name entered for this user" : user.name }}
                    <b-icon-pencil @click="showModal = true" class="text-success clickable"></b-icon-pencil>
                </h6>
                <div>
                    {{ user.email}}
                </div>
            </div>
            <div class="ml-3">
                <b-badge v-if="user.is_admin == 1 || (user.roles !== undefined && user.roles.filter((obj) => { return obj.admin == 1}).length > 0)" class="mr-1"
                         variant="danger">
                    Admin access
                </b-badge>
                <b-badge v-if="user.is_billing == 1 || (user.roles !== undefined && user.roles.filter((obj) => { return  obj.billing === 1}).length > 0)" class="mr-1"
                         variant="success">
                    Billing access
                </b-badge>
            </div>
            <div class="flex-fill  text-right">
                <a v-if="user.id !== $store.state.user.id" href="#"
                   @click="(e) => { e.preventDefault(); deleteModel();}">
                    <b-icon-trash class="text-primary"></b-icon-trash>
                </a>
            </div>
        </div>
        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false">
                    <b-icon-x-circle></b-icon-x-circle>
                </a>
            </div>
            <b-form-group
                    label-size="sm"
                    label="First name:">
                <b-form-input size="sm" v-model="firstName" placeholder="First name"/>
                <error-display v-model="errors" ident="first_name"></error-display>
            </b-form-group>
            <b-form-group
                    label-size="sm"
                    label="Last name:">
                <b-form-input size="sm" v-model="lastName" placeholder="Last name"/>
                <error-display v-model="errors" ident="last_name"></error-display>
            </b-form-group>
            <div>
                <b-button size="sm" @click="saveUserName" :disabled="this.isLoading"
                          class="text-left d-flex align-items-center"
                          variant="success">
                    Save
                </b-button>
            </div>
        </b-modal>
    </surround-loader>
</template>

<script>
    import SurroundLoader from "../SurroundLoader";
    import {organisationSetupUserNameChange, organisationSetupUsersDelete} from "../../modules/api/endpoints";
    import {deleteResource, saveResource} from "../../modules/api/methods";
    import {containsErrors} from "../../modules/helpers/helpers";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";

    export default {
        name: "SetupUserCell",
        components: {ErrorDisplay, SurroundLoader},
        props: {
            user: {}
        },
        data: function () {
            return {
                errors: {},
                showModal: false,
                isLoading: false,
                firstName: null,
                lastName: null,
            }
        },
        mounted() {
            this.firstName = this.user.first_name;
            this.lastName = this.user.last_name;
        },
        methods: {
            saveUserName() {
                var vm = this;
                vm.showModal = false;
                vm.errors = {};
                vm.isLoading = true;
                saveResource(organisationSetupUserNameChange(this.user.id),{first_name : this.firstName,last_name : this.lastName}).then((resp) => {
                    //UPDATE THE ORG TO ALLOW TO PROCEED TO NEXT STEP
                    vm.$emit('delete')
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(() => {
                    vm.isLoading = false;
                })
            },
            deleteModel() {
                this.isLoading = true;
                var vm = this;
                deleteResource(organisationSetupUsersDelete(vm.user.id)).then((resp) => {
                    vm.$emit('delete')
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadUsers);
                }).finally(() => {
                    this.isLoading = false;
                });

            }
        }
    }
</script>

<style scoped>

</style>
